function truncateStringToParent(el) {
    console.log(el.parentElement.offsetWidth);
    el.style.setProperty("width", `${el.parentElement.offsetWidth}px`);
    el.classList.add('truncate-text');
}

window.addEventListener('load', (event) => {
    let test = null;
    // document.querySelector("[data-marquee]").clientHeight();
    if (document.querySelector('[data-marquee]')) {
        [...document.querySelectorAll(".table")].forEach(el => {
            el.style.setProperty("--marqueeHeight", `${document.querySelector("[data-marquee]").clientHeight}px`);
        });
    }

    let breakpoint = 560;

    if (window.innerWidth > breakpoint) {

        [...document.querySelectorAll("[data-fit-text]")].forEach(el => {
            // We just need the length of the string as a CSS variable...

            let elFontSize = window.getComputedStyle(el).fontSize;
            let minFont = 14;

            if (el.offsetWidth == el.parentElement.offsetWidth) {
                if (parseFloat(elFontSize) > minFont) {
                    el.classList.add('apply-font-change');
                    let parentVw = (el.parentElement.offsetWidth / window.innerWidth) * 100;
                    el.style.setProperty("--width", `${parentVw}vw`);
                    el.style.setProperty("--length", el.innerText.length);
                } else {
                    truncateStringToParent(el);
                }
            }

        });
    }

});
